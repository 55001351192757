import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/Users/pgegenfurtner/Projekte/interness/atacama/atacama-packages/packages/web-core/src/templates/mdx/default.js";
import { graphql } from 'gatsby';
import Lightbox from '@interness/web-core/src/components/media/Lightbox/Lightbox';
import HeaderMedia from '@interness/theme-default/src/components/HeaderMedia';
import HeaderImage from '@interness/web-core/src/components/media/HeaderImage/HeaderImage';
import Separator from '@interness/web-core/src/components/structure/Separator/Separator';
import ResponsiveIFrame from '@interness/web-core/src/components/structure/iFrame/iFrame';
import { Flex, Box } from '@chakra-ui/react';
export const query = graphql`
  query {
    uhrmacherGallery: directusMediaCollection(name: {eq: "uhrmacher-galerie"}) {
      name
        images: media {
          sort
          file {
            localFile {
              name
              childImageSharp {
                fluid(maxWidth: 1600, quality: 95) {
                  presentationWidth
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
        thumbnails: media {
          sort
          file {
            localFile {
              name
              childImageSharp {
                fluid(maxWidth: 300, quality: 95) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
    }
    goldschmiedeGallery: directusMediaCollection(name: {eq: "goldschmiede-galerie"}) {
      name
        images: media {
          sort
          file {
            localFile {
              name
              childImageSharp {
                fluid(maxWidth: 1600, quality: 95) {
                  presentationWidth
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
        thumbnails: media {
          sort
          file {
            localFile {
              name
              childImageSharp {
                fluid(maxWidth: 300, quality: 95) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
    }
  }
`;
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const Wrapper = makeShortcode("Wrapper");
const Heading = makeShortcode("Heading");
const layoutProps = {
  query,
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <HeaderImage mdxType="HeaderImage">
  <HeaderMedia id='157-kortenhaus-service' mdxType="HeaderMedia" />
    </HeaderImage>
    <Wrapper width={960} mdxType="Wrapper">
      <hr></hr>
  <Heading id='uhrmacher' tag='h2' subtitle='Verantwortung für die Zeit und deren Messung' mdxType="Heading">Uhrmacher</Heading>
      <Flex flexWrap='wrap' mdxType="Flex">
        <Box width={['100%', '50%', '50%', '50%']} style={{
          textAlign: 'justify'
        }} mdxType="Box">
          <p>{`  Bei uns werden Uhren (sowohl Groß-als auch Kleinuhren) bei einer Revision komplett zerlegt, vor- und
nachgereinigt.`}</p>
          <p>{`  Besonderen Wert legen wir auf das Aufarbeiten des Uhrenäußeren, des Gehäuses.`}</p>
          <p>{`  Für Sie als Kunde bietet unser Service nicht nur die Sicherheit einer fachmännisch durchgeführten Reparatur
sondern
auch einen sichtbaren Aha-Effekt, wenn Sie Ihr gutes Stück wieder in Empfang nehmen.`}</p>
          <p>{`  Wir haben uns bewusst für mechanische Chronometer von Premiummarken entschieden, als der Siegeszug der Quarzuhren
begann.`}</p>
          <p>{`  Diese Entscheidung gegen den Trend und die Massenproduktion haben unsere Kunden mitgetragen und uns mit ihrem
Vertrauen auf unserem Weg bestärkt.`}</p>
          <p>{`  Dabei entwickeln wir uns natürlich weiter, ohne bestehende Werte aufzugeben.`}</p>
          <p>{`  Neben unserem hochwertigen Spezialangebot, bieten wir Ihnen auch fachgerechten Service.`}</p>
        </Box>
        <Box width={['100%', '50%', '50%', '50%']} pl={40} mdxType="Box">
          <h3 {...{
            "id": "unsere-serviceleistungen"
          }}>{`Unsere Serviceleistungen`}</h3>
          <ul>
            <li parentName="ul">{`Jeder Service an mechanischen und elektronischen Zeitmessern`}</li>
            <li parentName="ul">{`Reparaturen antiker Klein- und Großuhren`}</li>
            <li parentName="ul">{`Abholservice für Großuhren`}</li>
          </ul>
        </Box>
      </Flex>
  <Lightbox images={props.data.uhrmacherGallery.images} thumbnails={props.data.uhrmacherGallery.thumbnails} fromApi mdxType="Lightbox" />
  <Separator mdxType="Separator" />
  <Heading id='goldschmiede' tag='h2' subtitle='Aus Freude am Schönen' mdxType="Heading">Goldschmiede</Heading>
      <Flex flexWrap='wrap' mdxType="Flex">
        <Box width={['100%', '50%', '50%', '50%']} style={{
          textAlign: 'justify'
        }} mdxType="Box">
          <p>{`  Neben der Uhrmacherwerkstatt befindet sich auch unsere Goldschmiede im eigenen Hause.`}</p>
          <p>{`  Unsere Goldschmiedemeister sind erfahrene Handwerker, die auch das Fassen von Brillanten und Edelsteinen beherrschen.`}</p>
          <p>{`  Mit traditionellen Goldschmiedetechniken und einem ausgeprägten Gefühl für die Feinheiten der Materialien wie Titan,
Silber, Gold und Platin fertigen wir individuelle Schmuckstücke mit Unikatcharakter an.`}</p>
          <p>{`  Unser Ziel: die Freude, die wir im Umgang mit den schönen Materialien verspüren, soll auf das fertige Schmuckstück und
seine Trägerin / seinen Träger übergehen.`}</p>
          <p>{`  Ob Sonderanfertigungen, Umarbeitungen oder Reparaturen, unsere Goldschmiede finden mit Sicherheit die passende Lösung.`}</p>
        </Box>
        <Box width={['100%', '50%', '50%', '50%']} pl={40} mdxType="Box">
          <h3 {...{
            "id": "unsere-serviceleistungen-1"
          }}>{`Unsere Serviceleistungen`}</h3>
          <ul>
            <li parentName="ul">{`Pflege, Reparatur, Umarbeitung, und Anfertigung von Schmuck`}</li>
            <li parentName="ul">{`Fassen von Edelsteinen und Brillianten`}</li>
            <li parentName="ul">{`Aufarbeitung von Schmuckstücken`}</li>
            <li parentName="ul">{`Gravuren (Anhänger, Schildarmbänder, Feuerzeugen, Schlüsselanhänger, Ringe, Besteck, etc.)`}</li>
            <li parentName="ul">{`Ankauf von Altgold, Altsilber, und Altplatin ( z. B. Zahngold, Bruchgold, Münzen, nicht getragener Schmuck, Uhren
aus Edelmetall)`}</li>
          </ul>
        </Box>
      </Flex>
  <Lightbox images={props.data.goldschmiedeGallery.images} thumbnails={props.data.goldschmiedeGallery.thumbnails} fromApi mdxType="Lightbox" />
  <Separator mdxType="Separator" />
  <Heading id='altgold-ankauf' tag='h2' subtitle='Machen Sie Ihr Altgold zu Bargeld' mdxType="Heading">Altgold Ankauf</Heading>
      <Flex flexWrap='wrap' mdxType="Flex">
        <Box style={{
          textAlign: 'justify'
        }} mdxType="Box">
  <p>Bei Ihrem Juwelier Kortenhaus stehen faire Beratung und Ihre Zufriedenheit an erster Stelle.</p>
  <p>Altgold-Verkauf ist Vertrauenssache. Wir als erfahrene Gold-Experten sichern Ihnen eine unverbindliche
    und
    kostenlose Beratung zu. Wir begutachten Ihr Altgold auf den Feingehalt und den zu erwartenden Erlös.</p>
  <p>Ob Sie Ihr Schmuck-Gold zu den so ermittelten Bedingungen verkaufen möchten entscheiden Sie dann
    selbst.</p>
        </Box>
        <Box style={{
          textAlign: 'justify'
        }} mdxType="Box">
  <h3>So gehen wir beim Altgold Ankauf vor</h3>
  <p>
    Zunächst sortieren wir Ihr Altgold (Schmuckgold, Zahngold, Münzen etc.) nach Legierungsart. Bei nicht
    gestempelten Materialien wird der Feingehalt durch Probiersäuren bestimmt. Verwendbare Edelsteine
    können ausgefasst und verrechnet werden oder zur weiteren Verarbeitung zur Verfügung gestellt werden.
    Gerne beraten unsere Goldschmiedemeister Sie zu diesem Thema.
  </p>
  <h3>Schritt für Schritt zum fairen Preis</h3>
  <p>Zahngold wird zur genauen Gewichts- und Legierungsbestimmung aus dem Zahn geklopft und komplett vom
    übrigen Material gelöst. Bei größeren Altgold-Mengen sortieren unsere Goldschmiedemeister Ihr Altgold
    nach Feingehalt und stellen Ihnen eine Übersicht zusammen, die folgende Angaben enthält:</p>
  <ul>
    <li>enthaltene Legierungen (Feingehalt)</li>
    <li>Gewicht des Altgolds</li>
    <li>Der kalkulierte Ankaufspreis pro Gramm</li>
    <li>Gesamt-Ankaufspreis Ihres Goldes</li>
  </ul>
  <p>Unsere Kalkulation richtet sich nach den aktuellen Tageskursen. Die anfallenden Bearbeitungs-,
    Verarbeitungs-, Porto-, Einschmelz- und Analysekosten sind im Ankaufspreis bereits berücksichtigt.
    Nach der Ermittlung des Ankaufspreises entscheiden sie, ob Sie Ihr Altgold an uns verkaufen möchten.
    Im positiven Fall erhalten Sie sofort Bargeld.</p>
          <hr></hr>
          <h3 {...{
            "id": "film-zur-altgoldverwertung"
          }}>{`Film zur Altgoldverwertung`}</h3>
  <ResponsiveIFrame src='https://player.vimeo.com/video/496836725' type='vimeo' mdxType="ResponsiveIFrame" />
        </Box>
      </Flex>
      <hr></hr>
    </Wrapper>
    <div>
  <Wrapper width={960} mdxType="Wrapper">
  <HeaderMedia id='157-goldbarren' mdxType="HeaderMedia" />
        <hr></hr>
  <Heading tag='h2' subtitle='Investieren Sie in Gold-/ Silberbarren' id='goldbarren' mdxType="Heading">Gold-und
    Silberbarrenverkauf</Heading>
  <Box style={{
          textAlign: 'justify'
        }} mdxType="Box">
          <p>{`  In Krisenzeiten (z.B. Inflation, gesellschaftlichen Umbrüchen, Währungsumstellung, etc.) hat sich als Investition und
zur `}<strong parentName="p">{`Absicherung seines Vermögens schon seit jeher die Anlage in Gold bewährt.`}</strong>{` Die Kaufkraft von Gold bleibt im
Wesentlichen erhalten.Vermögensberater empfehlen ca. 5-15% seines Vermögens in Gold zu investieren. Wir bieten Ihnen
Goldbarren in verschiedenen Gewichtsgrößen, so haben wir folgende Goldbarren `}<strong parentName="p">{`in der Regel lagernd und sofort verfügbar:`}</strong></p>
  <Box textAlign="center" mdxType="Box">
            <p>{`  `}<strong parentName="p">{`1g | 2,5g | 5g | 10g | 20g | 31,1g | 50g | 100g`}</strong></p>
  </Box>
          <p>{`  Größere Barren 250g; 500g; und 1.000g können wir über unseren Gold- Lieferanten zeitnah besorgen. Bei diesen großen Barren ist allerdings eine Vorauszahlung notwendig und es gilt der vereinbarte Tagespreis zum Zeitpunkt der Bestellung.
`}<strong parentName="p">{`Unsere Goldbarren haben alle eine Individual-Nr. und ein Echtheitszertifikat.`}</strong></p>
          <p>{`  `}<strong parentName="p">{`Goldbarren sind nach §25c steuerbefreit.`}</strong>{` Dies gilt allerdings nicht für Silberbarren, diese sind MwST.-pflichtig.`}</p>
          <p>{`Unsere Preise richten sich nach dem aktuellen Tagespreis zzgl. einer Provision.`}</p>
          <p>{`Bei Barren die nicht lagernd sind, werden Versandkosten abhängig von Größe und Wert der Barren, separat berechnet.`}</p>
  </Box>
  <Box textAlign="center" mdxType="Box">
  Für evtl. Fragen zur Abwicklung stehen wir Ihnen gerne telefonisch unter 02104-27634 oder auch persönlich bei uns im Geschäft zur Verfügung.
  Vertrauen Sie Ihrem Fachmann und lassen Sie sich gerne von uns beraten!
  </Box>
        <hr></hr>
  </Wrapper>
    </div>


    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      